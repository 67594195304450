import "./MoveportfolioWorkbookPopup.css";

/* eslint-disable array-callback-return */
import React, { useState } from "react";

import { Button } from "@mui/material";
import { getPortfolioData } from "Config/API/get";
import { movePortfolio } from "Config/API/action";
import portfolioFiles from "Media/icons/portfolioFiles.png";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";
import { useSearchParams } from "react-router-dom";

const MovePortfolioWorkbookPopup = ({
  hide,
  selectedFolderId,
  token,
  bookmarkData,
  selectedTab,
  portfolio,
  orgId,
  selectedtype,
  parentUniqueId,
}) => {
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const [portfolioData, setPortfolioData] = useState(
    portfolio ? portfolio : ""
  );
  const [bookMark, setBookMark] = useState(bookmarkData);
  const [tabName, setTabName] = useState(selectedTab);
  const [moveToFolderId, setMoveToFolderId] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [lazyLoading, setLazyLoading] = useState(false);
  const loadingArray = ["", "", "", ""];
  const handleMoveHereClick = () => {
    setLoader();
    const response = movePortfolio(token, moveToFolderId, selectedFolderId);
    response.then((res) => {
      if (res?.status) {
        resetLoader();
        hide(false);
        toast(res?.message, { type: "success" });
        setSearchParams({
          type: selectedTab,
          folderId: moveToFolderId,
        });
      } else {
        resetLoader();
        toast(res?.message, { type: "error" });
      }
    });
  };

  const getPortfolio = (parent_unique_id) => {
    setLazyLoading(true);
    const response = getPortfolioData(
      token,
      selectedTab,
      parent_unique_id,
      orgId
    );
    response.then((res) => {
      if (res.data.status) {
        setLazyLoading(false);
        setPortfolioData(res?.data?.data);
        setBookMark(res.data.bookmarkData.splice(1));
      } else {
        toast(res.data.message, {
          type: "error",
        });
      }
    });
  };

  const goToRootFolder = (value) => {
    setTabName(value);
    setBookMark([]);
    getPortfolio("0");
    setMoveToFolderId("0");
  };
  const renderItemName = (item) => {
    return item?.name?.substr(0, 17);
  };

  return (
    <>
      <div className="move-folder-parent">
        <div className="move-folder-main">
          <div className="move-folder-heading">
            <p>Move {selectedTab === "workbook" ? "Workbook" : "Portfolio"}</p>
            <div className="rename-cross" onClick={() => hide(false)}>
              X
            </div>
          </div>
          <div className="move-folder-bookmarkbar">
            <button
              onClick={() => {
                goToRootFolder(tabName);
              }}
            >
              {tabName === "workbook" ? "Workbooks" : "Portfolio Files"}
            </button>
            {bookMark.map((item) => {
              return (
                <>
                  {">"}
                  <button
                    onClick={() => {
                      getPortfolio(item?.unique_id);
                    }}
                  >
                    {renderItemName(item)}
                  </button>
                </>
              );
            })}
          </div>
          <div className="move-folder-wrapper">
            {lazyLoading
              ? loadingArray.map((item) => {
                  return (
                    <div
                      className="portfolio-content-item"
                      style={{ borderColor: "#ececec" }}
                    >
                      <div>
                        <div className="loading-content-image animation" />
                        <span
                          className="loading-span animation"
                          style={{ marginLeft: "10px" }}
                        ></span>
                      </div>
                    </div>
                  );
                })
              : selectedTab === "portfolio"
              ? portfolioData
                  ?.filter(
                    (item) =>
                      item?.type === "folder" &&
                      item?.unique_id !== selectedFolderId
                  )
                  ?.map((item) => (
                    <button
                      onClick={() => {
                        getPortfolio(item?.unique_id);
                        setMoveToFolderId(item?.unique_id);
                      }}
                    >
                      <img src={portfolioFiles} alt="Folder" />
                      <div className="directory-folder-single-aliasname">
                        <span>{renderItemName(item)}</span>
                        <span>
                          {item?.alias_name ? item?.alias_name : null}
                        </span>
                      </div>
                    </button>
                  ))
              : portfolioData
                  ?.filter(
                    (item) =>
                      item?.type === "folder" &&
                      item?.unique_id !== selectedFolderId &&
                      item?.classify === "workbook" &&
                      item?.subType !== "workbookFile"
                  )
                  ?.map((item) => (
                    <button
                      onClick={() => {
                        getPortfolio(item?.unique_id);
                        setMoveToFolderId(item?.unique_id);
                      }}
                    >
                      <img src={portfolioFiles} alt="Folder" />
                      <div className="directory-folder-single-aliasname">
                        <span>{item?.name}</span>
                        <span>
                          {item?.alias_name ? item?.alias_name : null}
                        </span>
                      </div>
                    </button>
                  ))}
          </div>
        </div>
        <div className="move-folder-button-container">
          <Button
            variant="contained"
            style={{
              background:
                (selectedtype === "file" ||
                  selectedTab === "workbook" ||
                  parentUniqueId === "0") &&
                bookMark?.length === 0
                  ? "rgba(0, 0, 0, 0.12)"
                  : "#5ca127",
              whiteSpace: "nowrap",
            }}
            onClick={() => handleMoveHereClick()}
            disabled={
              (selectedtype === "file" ||
                selectedTab === "workbook" ||
                parentUniqueId === "0") &&
              bookMark?.length === 0
            }
          >
            Move Here
          </Button>
        </div>
      </div>
      {loader}
    </>
  );
};

export default MovePortfolioWorkbookPopup;
