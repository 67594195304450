import "./AddAmniusAdmin.css";

import { IconButton, TextField } from "@mui/material";
import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { createAmniusAdmin } from "Config/API/action";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const AddAmniusAdmin = ({ popup, hide }) => {
  const token = localStorage.getItem("token");
  const orgName = useSelector(
    (state) => state.renderGeneralInfo?.organization_name
  );
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const createAdminRes = createAmniusAdmin(
      token,
      userName?.trim(),
      userEmail?.trim(),
      orgName
    );
    createAdminRes.then((res) => {
      if (res?.status) {
        toast(res?.message, {
          type: "success",
        });
        window.location.reload();
      } else {
        toast(res?.message, {
          type: "error",
        });
      }
    });
  };

  return popup ? (
    <>
      <div className="add-amnius-admin-container">
        <div>
          <div className="add-amnius-admin-top-wrapper">
            <div className="add-amnius-admin-head">Add Amnius Admin</div>
            <div className="add-amnius-admin-close">
              <IconButton
                onClick={() => {
                  setUserEmail("");
                  setUserName("");
                  hide(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          <div className="add-amnius-admin-form">
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="add-amnius-org-name">
                <TextField
                  required
                  fullWidth
                  placeholder="Ex: XYZ"
                  label="Enter Org Name"
                  variant="outlined"
                  type="text"
                  style={{ margin: "10px 0", marginTop: "unset" }}
                  value={orgName}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="add-amnius-admin-name">
                <TextField
                  required
                  fullWidth
                  placeholder="Ex: John"
                  label="Enter User Name"
                  variant="outlined"
                  type="text"
                  style={{ margin: "10px 0" }}
                  value={userName}
                  onChange={(e) => {
                    setUserName(e.target.value.trimStart());
                  }}
                />
              </div>
              <div className="add-amnius-admin-email">
                <TextField
                  required
                  fullWidth
                  placeholder="Ex: abc@xyz.com"
                  label="Enter User Email"
                  variant="outlined"
                  type="email"
                  style={{ margin: "10px 0" }}
                  value={userEmail}
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                  }}
                />
              </div>
              <div>
                <button type="submit">ADD ADMIN</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default AddAmniusAdmin;
