import "./AddWebLink.css";

import React, { useState } from "react";
import {
  createFolderWebLink,
  createInternalFolderWebLink,
} from "Config/API/update";
import { folderData, getInternalFolder } from "Config/API/get";
import {
  internalFolderDataAction,
  selectedFileDataAction,
  selectedFolderDataAction,
  selectedFolderRootDataAction,
  storeFolderDataAction,
} from "Redux/action/get.action";

import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import useFullPageLoader from "Hooks/useFullPageLoader";

const AddWebLinkToFileExplorer = ({
  hide,
  parent_unique_id,
  isInternal,
  conn_id,
}) => {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const [linkName, setLinkName] = useState("");
  const [link, setLink] = useState("");

  const onInternalFolderMoveSuccess = (array) => {
    array.map((item) => {
      if (item?.unique_id === parent_unique_id) {
        dispatch(selectedFolderDataAction(item));
        dispatch(internalFolderDataAction(item?.items));
      } else onInternalFolderMoveSuccess(item?.items);
    });
  };

  const onConnectedFolderMoveSuccess = (array) => {
    array.map((item) => {
      if (item?.unique_id === parent_unique_id) {
        dispatch(selectedFileDataAction(item?.items));
        dispatch(selectedFolderDataAction(item));
      } else onConnectedFolderMoveSuccess(item?.items);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader();
    if (isInternal) {
      const response = createInternalFolderWebLink(
        token,
        linkName,
        parent_unique_id,
        link
      );
      response.then((res) => {
        if (res.status) {
          const internalFolderApiResponse = getInternalFolder(token);
          internalFolderApiResponse.then((ress) => {
            resetLoader();
            toast(res.message, {
              type: "success",
            });
            hide(false);
            onInternalFolderMoveSuccess(ress?.result[0]?.items);
            dispatch(selectedFolderRootDataAction(ress?.result));
          });
        } else {
          resetLoader();
          toast(res.message, {
            type: "error",
          });
        }
      });
    } else {
      const response = createFolderWebLink(
        token,
        linkName,
        parent_unique_id,
        link,
        conn_id
      );
      response.then((res) => {
        if (res.status) {
          const allFolderApiResponse = folderData(token);
          allFolderApiResponse.then((ress) => {
            resetLoader();
            toast(res.message, {
              type: "success",
            });
            hide(false);
            const filteredOrg = ress?.result?.filter(
              (item) => item?.conn_id?.toString() === conn_id?.toString()
            );
            onConnectedFolderMoveSuccess(filteredOrg);
            dispatch(storeFolderDataAction(ress?.result));
          });
        } else {
          resetLoader();
          toast(res.message, {
            type: "error",
          });
        }
      });
    }
  };

  return (
    <div className="add-web-link-parent">
      <div className="add-web-link-main">
        <form
          className="add-web-link-form-body"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div className="add-web-link-heading">
            <div className="rename-cross" onClick={() => hide(false)}>
              X
            </div>
          </div>
          <div className="add-web-link-wrapper">
            <div className="add-web-link-fields">
              <label>Add Web Link</label>
            </div>

            <div className="add-web-link-fields">
              <input
                type="text"
                required
                placeholder="Add Link Name"
                value={linkName}
                onChange={(e) => setLinkName(e.target.value.trimStart())}
              />
            </div>
            <div className="add-web-link-fields">
              <input
                type="text"
                required
                placeholder="Paste URL here"
                value={link}
                onChange={(e) => setLink(e.target.value.trimStart())}
              />
            </div>

            <div className="add-web-link-fields">
              <button type="submit">Submit</button>
            </div>
          </div>
        </form>
      </div>
      {loader}
    </div>
  );
};

export default AddWebLinkToFileExplorer;
