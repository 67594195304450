// ALERT
export const SET_ALERT = "SET_ALERT";
export const RESET_ALERT = "RESET_ALERT";
export const SET_DIALOG = "SET_DIALOG";
export const RESET_DIALOG = "RESET_DIALOG";

// API
export const LOGIN_STATUS = "LOGIN_STATUS";
export const GET_APPOINTMENTS_OVERVIEW = "GET_APPOINTMENTS_OVERVIEW";
export const GET_GENERAL_INFO = "GET_GENERAL_INFO";
export const USER_LOGOUT = "USER_LOGOUT";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_FULL_NOTIFICATION = "GET_FULL_NOTIFICATION";
export const GET_NOTIFICATION_BADGE = "GET_NOTIFICATION_BADGE";
export const STORE_FOLDER_DATA = "STORE_FOLDER_DATA";
export const SELECT_FILE_DATA = "SELECT_FILE_DATA";
export const SELECT_FOLDER_DATA = "SELECT_FOLDER_DATA";
export const SELECT_FOLDER_ROOT_DATA = "SELECT_FOLDER_ROOT_DATA";
export const INTERNAL_FOLDER_DATA = "INTERNAL_FOLDER_DATA";
export const GET_ORGANIZATION_DATA = "GET_ORGANIZATION_DATA";
export const GET_TRASH_DATA = "GET_TRASH_DATA";
export const CHAT_PROFILE = "CHAT_PROFILE";
export const USER_LIST_DATA = "USER_LIST_DATA";
export const GROUP_DATA = "GROUP_DATA";
export const GROUP_CHAT_DATA = "GROUP_CHAT_DATA";
export const USER_LIST = "USER_LIST";
export const EVENT_LIST = "EVENT_LIST";
export const APPOINTMENT_EVENT_LIST = "APPOINTMENT_EVENT_LIST";
export const VIDEO_EVENT_LIST = "VIDEO_EVENT_LIST";
export const TICKET_CHAT_DATA = "TICKET_CHAT_DATA";
export const SECRET_KEY = "SECRET_KEY";
export const CREDIT_BALANCE = "CREDIT_BALANCE";
export const USABLE_CREDIT_BALANCE = "USABLE_CREDIT_BALANCE";
export const ORGANIZATION_STORAGE_DATA = "ORGANIZATION_STORAGE_DATA";

// GLOBAL
export const GLOBAL_OPTION_BUTTON = "GLOBAL_OPTION_BUTTON";
export const HASH_ID = "HASH_ID";
export const CURRENT_FOLDER = "CURRENT_FOLDER";
export const CURRENT_ORGANIZATION = "CURRENT_ORGANIZATION";
export const FOLER_AUTOMATION_ID = "FOLER_AUTOMATION_ID";
export const NEW_MSG_ALERT = "NEW_MSG_ALERT";
export const SELECTED_ORGANIZATION_ST = "SELECTED_ORGANIZATION_ST";
export const TICKET_ID_DETAIL = "TICKET_ID_DETAIL";
export const RAISE_REQUEST_ID = "RAISE_REQUEST_ID";
export const RAISE_REQUEST_DATA = "RAISE_REQUEST_DATA";

// PAGE DECIDER
export const SERVICE_PAGE_TYPE = "SERVICE_PAGE_TYPE";
export const CONNECTED_AMNIUS_USER = "CONNECTED_AMNIUS_USER";
