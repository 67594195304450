import httpRequest from "Config/httpsClient";

// update logo
export const updateImage = async (img, token) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "update-logo",
      { logo: img },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// update organization details
export const updateOrganization = async (
  organization_name,
  organization_website,
  organization_phone_number,
  logo,
  time_zone,
  address,
  street,
  city,
  state,
  country,
  zipcode,
  token,
  country_code,
  corresponding_zone
) => {
  try {
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const promise = await httpRequest.post(
      "update-organization",
      {
        organization_name,
        organization_website,
        organization_phone_number,
        logo,
        time_zone,
        address,
        street,
        city,
        state,
        country,
        zipcode,
        country_code,
        corresponding_zone,
      },
      {
        headers: header,
      }
    );
    return await promise.data;
  } catch (e) {}
};

// update user information
export const updateUser = async (
  emp_id,
  username,
  firstname,
  lastname,
  email,
  phone,
  office_phone,
  extension,
  designation,
  password,
  image,
  org_id,
  category,
  scope,
  dob,
  location,
  token
) => {
  try {
    if (org_id?.toString() === "0") org_id = "";
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "update-employee",
      {
        emp_id,
        username,
        firstname,
        lastname,
        email,
        phone,
        office_phone,
        extension,
        designation,
        password,
        image,
        org_id,
        category,
        dob,
        location,
        scope,
      },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// update group
export const updateGroup = async (
  token,
  groupUniqueId,
  add,
  remove,
  name,
  logo
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "update-group",
      { groupUniqueId, add, remove, name, logo },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// update respective rate
export const updateRespectiveRate = async (token, rate_type, rate, rateId) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "set-rate",
      { rate_type, rate, rateId },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// update Bank Details
export const updateBankDetails = async (
  token,
  accountNumber,
  accountHolderName,
  routingNumber,
  bankId,
  bankName
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "add-bank",
      { accountNumber, accountHolderName, routingNumber, bankId, bankName },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// update Credit Card Details
export const updateCreditCardDetails = async (
  token,
  exp_month,
  exp_year,
  cardNumber,
  cvc,
  zipCode,
  pmId
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "add-card",
      { exp_month, exp_year, cardNumber, cvc, zipCode, pmId },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// remove Credit Card
export const removeCreditCard = async (token, pmId) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "remove-card",
      { pmId },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// set default card
export const setDefaultCard = async (token, pmId) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "set-payment-method-default",
      { pmId },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// create Transfer Out Request
export const createTransferOutRequest = async (
  token,
  userName,
  email,
  accountNumber,
  credit,
  routingNumber,
  comment
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "send-transfer-out-request",
      { userName, email, accountNumber, credit, routingNumber, comment },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// create portfolio or workbook
export const createPortfolioAndWorkbookFolders = async (
  token,
  name,
  classify,
  parent_unique_id,
  key,
  subType
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "create-portfolio-folder",
      { name, classify, parent_unique_id, key, subType },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// delete portfolio and workbook
export const deletePortfolioAndWorkBook = async (token, portfolioId) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "delete-portfilio",
      { portfolioId },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// create weblinks
export const createWebLink = async (
  token,
  name,
  classify,
  parent_unique_id,
  key,
  path
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "add-web-link",
      { name, classify, parent_unique_id, key, path },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// create folder weblinks
export const createFolderWebLink = async (
  token,
  name,
  parent_unique_id,
  path,
  conn_id
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "add-folder-weblink",
      { name, parent_unique_id, path, conn_id },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// create internal folder weblinks
export const createInternalFolderWebLink = async (
  token,
  name,
  parent_unique_id,
  path
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "add-internal-weblink",
      { name, parent_unique_id, path },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// replace weblinks
export const replaceWebLink = async (token, name, unique_id, path) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "replace-web-link",
      { name, unique_id, path },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// create workbook files
export const addWorkBookFiles = async (
  token,
  conn_id,
  parent_unique_id,
  unique_id
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "add-workbook-files",
      { conn_id, parent_unique_id, unique_id },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// remove workbook files
export const deleteWorkBookFile = async (token, uniqueIds) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "remove-workbook-file",
      { uniqueIds },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// change workbool file sequence
export const changeWorkbookFileSequence = async (
  token,
  uniqueIds,
  parentUniqueId
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "file-sequence",
      { uniqueIds, parentUniqueId },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// assign Workbook
export const assignWorkBook = async (
  token,
  parent_unique_id,
  conn_id,
  assign_to
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "assign-workbook",
      { parent_unique_id, conn_id, assign_to },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// unassign Workbook
export const unassignWorkBook = async (token, parent_unique_id, assign_to) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "delete-assign-workbook",
      { parent_unique_id, assign_to },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// complete workbookfiles
export const completeWorkbookFile = async (
  token,
  uniqueId,
  parentUniqueId,
  assignTo,
  assignStatus,
  isWorkbookCompleted
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "update-assign-workbook",
      { uniqueId, parentUniqueId, assignTo, assignStatus, isWorkbookCompleted },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// report admin regarding subscription end
export const reportAdmin = async (token, orgName) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "report-admin",
      { orgName },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// to change default payment method for automated deductions
export const changeDefaultPaymentMethod = async (token, paymentPreference) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "update-payment-preference",
      { paymentPreference },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};
