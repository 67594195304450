import "./Headers.css";

import { Cell, Pie, PieChart } from "recharts";
import React, { useEffect, useRef, useState } from "react";
import {
  getClientCreditBalance,
  getGeneralInfo,
  getNotificationTabsTotalCount,
  getStorageData,
  getSuperAdminClientCreditBalance,
  getSuperAdminStorageData,
  getUserDetails,
} from "Config/API/get";
import {
  getOrganizationStorageDataAction,
  getRecentNotificationCountAction,
  getStripeCreditBalanceAction,
  getUsableCreditBalanceAction,
} from "Redux/action/get.action";
import { useDispatch, useSelector } from "react-redux";

import CloudStorageSubscriptionPopup from "Components/Popup/CloudStorageSubscriptionPopup/CloudStorageSubscriptionPopup";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpIcon from "@mui/icons-material/Help";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import RenderSearchBar from "Components/SearchBar/Container/RenderSearchBar";
import StarIcon from "@mui/icons-material/Star";
import { logging } from "Redux/action/auth.action";
import logo from "Media/Amnius_connect_noText.png";
import { renderGeneralInfo } from "Redux/action/render.action";
import searchLogo from "Media/icons/Search.svg";
import useIsAmniusOrganization from "Hooks/useIsAmniusOrganization";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";

const Headers = () => {
  const navigate = useNavigate();
  const isMediumView = useMediaQuery("(max-width:500px)");
  const isSmallView = useMediaQuery("(max-width:400px)");
  const isOrgAmnius = useIsAmniusOrganization();
  const token = localStorage.getItem("token");
  const from = localStorage.getItem("from");
  const dispatch = useDispatch();
  const notificationCount = useSelector(
    (state) => state.getRecentNotificationCountReducer
  );
  const our_role = useSelector((state) => state.authenticationReducer?.role);
  const credit = useSelector((state) => state.getStripeCreditBalanceReducer);
  const organizationStorageData = useSelector(
    (state) => state.getOrganizationStorageDataReducer
  );
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [headersMoreOptions, setHeadersMoreOptions] = useState(false);
  const [
    showCloudStorageSubscriptionLimit,
    setShowCloudStorageSubscriptionLimit,
  ] = React.useState(false);
  const usedStorageData =
    +(
      (organizationStorageData?.usedSize * 100) /
      organizationStorageData?.totalAvailableSize
    )?.toFixed(2) || 0.0;

  const usedStorageColor =
    usedStorageData < 40
      ? "#5CA127"
      : usedStorageData < 80
      ? "#EDA74C"
      : "#CB1212";

  const storageDataArray = [
    {
      name: " ",
      value: usedStorageData > 0 && usedStorageData < 4 ? 4 : usedStorageData,
    },
    { name: " ", value: 100 - usedStorageData },
  ];
  const COLORS = [usedStorageColor, "#E3ECFC"];

  const desktopRef = useRef(null);
  const mobileRef = useRef(null);
  const mobileOptions = useRef(null);
  const wholeRef = useRef(null);

  useOutsideAlerter(desktopRef);
  useOutsideAlerter(mobileRef);
  useOutsideAlerter(mobileOptions);
  useOutsideAlerter(wholeRef);

  useEffect(() => {
    if (from) {
      getGeneralData();
    }
    // getting users credit balance
    getUserCredit();
    // getting users Updated Details
    getuserData();
    // get Notification Count
    getNotificationTabsTotalCountHandler();
    // get storage data
    getOrganizationStorageData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGeneralData = () => {
    const received_info = getGeneralInfo(token);
    received_info
      .then((res) => {
        localStorage.removeItem("from");
        if (res?.status) {
          dispatch(renderGeneralInfo(res?.org_info));
        }
      })
      .catch((e) => {});
  };

  const getUserCredit = () => {
    if (our_role !== "super_admin") {
      const credit_status = getClientCreditBalance(token);
      credit_status.then((res) => {
        if (res?.data?.status) {
          const availableCredit = res?.data?.data[0]?.amount;
          const alteredValue = formattingFn(availableCredit);
          dispatch(getStripeCreditBalanceAction(alteredValue));
          dispatch(
            getUsableCreditBalanceAction(res?.data?.data[0]?.usableAmount)
          );
        }
      });
    } else {
      const credit_status = getSuperAdminClientCreditBalance(token);
      credit_status.then((res) => {
        if (res?.data?.status) {
          const availableCredit = res?.data?.data?.balance;
          const alteredValue = formattingFn(availableCredit);
          dispatch(getStripeCreditBalanceAction(alteredValue));
          dispatch(
            getUsableCreditBalanceAction(res?.data?.data[0]?.usableAmount)
          );
        }
      });
    }
  };

  const getuserData = () => {
    const apiResponse = getUserDetails(token);
    apiResponse.then((res) => {
      if (res?.data?.status) {
        if (res?.data?.data?.isStorageSubscription.toString() === "false") {
          setShowCloudStorageSubscriptionLimit(true);
        }
        dispatch(logging(res.data.data));
      }
    });
  };

  const getNotificationTabsTotalCountHandler = () => {
    const response = getNotificationTabsTotalCount(token);
    response.then((res) => {
      if (res?.status) {
        dispatch(
          getRecentNotificationCountAction(res?.TotalUnreadNotificationCount)
        );
      }
    });
  };

  const getOrganizationStorageData = () => {
    if (our_role !== "super_admin") {
      const response = getStorageData(token, "");
      response.then((res) => {
        if (res?.data?.status) {
          dispatch(getOrganizationStorageDataAction(res?.data?.data));
        }
      });
    } else {
      const response = getSuperAdminStorageData(token);
      response.then((res) => {
        if (res?.data?.status) {
          dispatch(getOrganizationStorageDataAction(res?.data?.data));
        }
      });
    }
  };

  const formattingFn = (availableCredit) => {
    const creditString = availableCredit?.toString();
    const hasDecimal = creditString?.includes(".");
    if (hasDecimal) {
      const formattedCredit =
        creditString.split(".")[1]?.length > 1
          ? availableCredit
          : `${availableCredit}0`;
      return formattedCredit;
    } else {
      return `${availableCredit}.00`;
    }
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          ref.current.classList.add("hide-dropdown");
        } else {
          if (ref.current !== null) {
            ref.current.classList.remove("hide-dropdown");
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <>
      <div className="header-container" ref={wholeRef}>
        <div className="header-logo">
          <img
            src={logo}
            alt="logo"
            onClick={() => {
              navigate("/dashboard");
            }}
          />
          <div className="header-credit-storage-container">
            <div
              className="credits-icon-header"
              onClick={() => {
                navigate("/money-credit-dashboard");
              }}
            >
              <StarIcon />
              <span>
                <span>CREDITS</span>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: isSmallView ? "14px" : "18px",
                  }}
                >
                  {credit ? credit : 0}
                </span>
              </span>
            </div>
            <div
              className="storage-icon-header"
              onClick={() => {
                navigate("/organization-storage-dashboard");
              }}
            >
              <PieChart
                width={50}
                height={50}
                className="header-storage-pie-chart"
              >
                <Pie
                  data={storageDataArray}
                  cx={10}
                  cy={20}
                  innerRadius={8}
                  dataKey="value"
                >
                  {storageDataArray.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
              <span style={{ marginLeft: "-12px" }}>
                <span>STORAGE</span>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: isSmallView ? "14px" : "18px",
                  }}
                >
                  {usedStorageData}%
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="header-search desktop-search" ref={desktopRef}>
          <RenderSearchBar device="desktop" />
        </div>
        <div className="header-search mobile-search" ref={mobileRef}>
          {searchBarOpen ? (
            <RenderSearchBar device="mobile" searchBarOpen={searchBarOpen} />
          ) : null}
        </div>
        <div className="header-options">
          {isMediumView ? (
            <>
              <div className="header-search-icon">
                <img
                  src={searchLogo}
                  alt="search icon"
                  onClick={() => setSearchBarOpen(!searchBarOpen)}
                />
              </div>
              <div className="header-notification">
                <IconButton
                  onClick={() => {
                    navigate({
                      pathname: "/notification",
                      search: "?type=video",
                    });
                  }}
                >
                  <div className="header-notification-badge">
                    <NotificationsIcon />
                    {notificationCount > 0 && (
                      <div className="header-notification-counter">
                        {notificationCount > 99 ? "99+" : notificationCount}
                      </div>
                    )}
                  </div>
                </IconButton>
              </div>
              <div
                className="header-notification"
                style={{ position: "relative" }}
              >
                <IconButton
                  onClick={() => setHeadersMoreOptions(!headersMoreOptions)}
                >
                  <MoreVertIcon />
                  {headersMoreOptions && (
                    <div
                      className="headers-hamburger-items"
                      ref={mobileOptions}
                      style={{ bottom: !isOrgAmnius ? "-88px" : "-52px" }}
                    >
                      {/* add menu items here for organization */}
                      <div>
                        <IconButton
                          onClick={() => {
                            navigate({
                              pathname: "/trash",
                              search: "?type=filesAndFolders",
                            });
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                      {!isOrgAmnius && (
                        <div>
                          <IconButton
                            onClick={() => {
                              navigate("/contact-support");
                            }}
                          >
                            <HelpIcon />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  )}
                </IconButton>
              </div>
            </>
          ) : (
            <>
              <div className="header-search-icon" style={{ display: "none " }}>
                <img
                  src={searchLogo}
                  alt="search icon"
                  onClick={() => setSearchBarOpen(!searchBarOpen)}
                />
              </div>
              <div className="header-delete">
                <IconButton
                  onClick={() => {
                    navigate({
                      pathname: "/trash",
                      search: "?type=filesAndFolders",
                    });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
              <div className="header-notification">
                <IconButton
                  onClick={() => {
                    navigate({
                      pathname: "/notification",
                      search: "?type=video",
                    });
                  }}
                >
                  <div className="header-notification-badge">
                    <NotificationsIcon />
                    {notificationCount > 0 && (
                      <div className="header-notification-counter">
                        {notificationCount > 99 ? "99+" : notificationCount}
                      </div>
                    )}
                  </div>
                </IconButton>
              </div>
              {!isOrgAmnius && (
                <div className="header-help">
                  <IconButton
                    onClick={() => {
                      navigate("/contact-support");
                    }}
                  >
                    <HelpIcon />
                  </IconButton>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {showCloudStorageSubscriptionLimit && <CloudStorageSubscriptionPopup />}
    </>
  );
};

export default Headers;
