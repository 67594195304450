import "./DateInput.css";

import React, { useEffect, useState } from "react";

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

const Dropdown = ({ options, value, onChange, placeholder }) => (
  <select value={value} onChange={onChange}>
    <option key="blankKey" hidden value>
      {placeholder}
    </option>
    {options.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ))}
  </select>
);

const DateInput = ({
  selectedDay,
  selectedMonth,
  selectedYear,
  setSelectedDay,
  setSelectedMonth,
  setSelectedYear,
}) => {
  const [isAdult, setIsAdult] = useState(true);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const daysInMonth = {
    January: 31,
    February: 28,
    March: 31,
    April: 30,
    May: 31,
    June: 30,
    July: 31,
    August: 31,
    September: 30,
    October: 31,
    November: 30,
    December: 31,
  };

  useEffect(() => {
    if (selectedYear && selectedMonth && selectedDay) {
      calCulateAge(
        new Date(
          selectedYear,
          months.findIndex((month) => month === selectedMonth) + 1,
          selectedDay
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, selectedMonth, selectedDay]);

  const handleDayChange = (e) => {
    setSelectedDay(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const generateDays = () => {
    const selectedMonthDays = getDaysInMonth(selectedMonth, selectedYear);
    const days = Array.from(
      { length: selectedMonthDays },
      (_, index) => index + 1
    );
    return days;
  };

  const generateYears = () => {
    const years = Array.from(
      { length: 100 },
      (_, index) => currentYear - index
    );
    return years;
  };

  const getDaysInMonth = (month, year) => {
    if (month === "February") {
      return isLeapYear(year) ? 29 : 28;
    }
    const daysInMonth = {
      January: 31,
      February: 28,
      March: 31,
      April: 30,
      May: 31,
      June: 30,
      July: 31,
      August: 31,
      September: 30,
      October: 31,
      November: 30,
      December: 31,
    };
    return daysInMonth[month];
  };

  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  const days = generateDays();
  const years = generateYears();

  const calCulateAge = (dob) => {
    const diff = Date.now() - dob.getTime();
    const ageDiff = new Date(diff);
    if (Math.abs(ageDiff.getUTCFullYear() - 1970) >= 18) {
      setIsAdult(true);
    } else {
      setIsAdult(false);
    }
  };

  return (
    <>
      <div className="date-input-selection-wrapper">
        <div>
          <Dropdown
            options={Object.keys(daysInMonth)}
            value={selectedMonth}
            onChange={handleMonthChange}
            placeholder={"Select Month"}
          />
        </div>
        <div>
          <Dropdown
            options={days}
            value={selectedDay}
            onChange={handleDayChange}
            placeholder={"Select Date"}
          />
        </div>
        <div>
          <Dropdown
            options={years}
            value={selectedYear}
            onChange={handleYearChange}
            placeholder={"Select Year"}
          />
        </div>
      </div>
      {isAdult ? (
        ""
      ) : (
        <p style={{ color: "#c50000" }}>
          Your not an adult! Please select a valid date.
        </p>
      )}
    </>
  );
};

export default DateInput;
