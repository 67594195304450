import {
  CONNECTED_AMNIUS_USER,
  CURRENT_ORGANIZATION,
  FOLER_AUTOMATION_ID,
  GLOBAL_OPTION_BUTTON,
  HASH_ID,
  RAISE_REQUEST_DATA,
  RAISE_REQUEST_ID,
  SELECTED_ORGANIZATION_ST,
  TICKET_ID_DETAIL,
  USER_LIST,
} from "Config/ActionType/action.type";

const initial = "";

export const globalOptionButtonReducer = (state = initial, action) => {
  switch (action?.type) {
    case GLOBAL_OPTION_BUTTON:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const hashIdReducer = (state = initial, action) => {
  switch (action?.type) {
    case HASH_ID:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const userListReducer = (state = initial, action) => {
  switch (action?.type) {
    case USER_LIST:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const currentVisitOrgReducer = (state = initial, action) => {
  switch (action?.type) {
    case CURRENT_ORGANIZATION:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const currentFolderAutomationReducer = (state = initial, action) => {
  switch (action?.type) {
    case FOLER_AUTOMATION_ID:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const selectedOrganizationSTReducer = (state = initial, action) => {
  switch (action?.type) {
    case SELECTED_ORGANIZATION_ST:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const notConnectedAmniusReducer = (state = initial, action) => {
  switch (action?.type) {
    case CONNECTED_AMNIUS_USER:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const ticketIdReducer = (state = initial, action) => {
  switch (action?.type) {
    case TICKET_ID_DETAIL:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const raiseRequestIdReducer = (state = initial, action) => {
  switch (action?.type) {
    case RAISE_REQUEST_ID:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const raiseRequestDataReducer = (state = initial, action) => {
  switch (action?.type) {
    case RAISE_REQUEST_DATA:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};
