import { dynamicUrl } from "Config/httpsClient";
import httpRequest from "Config/httpsClient";
import moment from "moment-timezone";
import { toast } from "react-toastify";

// add user
export const addUser = async (
  username,
  firstname,
  lastname,
  email,
  designation,
  phone,
  office_phone,
  extension,
  category,
  location,
  dob,
  scope,
  token
) => {
  try {
    let tmz = moment.tz.guess();
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "create-employee",
      {
        username,
        firstname,
        lastname,
        email,
        designation,
        phone,
        office_phone,
        extension,
        category,
        location,
        dob,
        scope,
        custom_timezone: tmz,
      },
      {
        headers: header,
      }
    );

    return await promise.data;
  } catch (e) {}
};

// delete user
export const deleteUser = async (id, token) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "delete-employee",
      { emp_id: id },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// active inactive employee
export const activeInactiveEmployee = async (status, emp_id, token) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token,
    };
    const promise = await httpRequest.post(
      "active-inactive-employee",
      { status, emp_id },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// create-location-category
export const createLocationCategory = async (
  token,
  type,
  category_location_val,
  location_id
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "create-location-category",
      { type, category_location_val, location_id },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};
// request connection
export const requestConnection = async (conn_id, token) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "request-connection",
      { conn_id },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// action on request
export const actionConnectionRequest = async (
  conn_id,
  action_status,
  notification_id,
  token
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "action-connection-request",
      {
        conn_id,
        action_status,
        notification_id,
      },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// add a folder
export const addFolderRequest = async (
  conn_id,
  name,
  parent_unique_id,
  token
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "create-folder",
      {
        conn_id,
        name,
        parent_unique_id,
      },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// add a internal folder
export const addInternalFolderRequest = async (
  name,
  parent_unique_id,
  token
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "create-internal-folder",
      {
        name,
        parent_unique_id,
      },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// add a file
export const addFileRequest = async (
  key,
  conn_id,
  parent_unique_id,
  name,
  token
) => {
  try {
    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "multipart/form-data");
    myHeaders.append("token", token);
    myHeaders.append("key", key);
    myHeaders.append("conn_id", conn_id);
    myHeaders.append("parent_unique_id", parent_unique_id);

    var formdata = new FormData();
    formdata.append("name", name, name.name);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
      // mode: "no-cors",
    };

    return await (
      await fetch(`${dynamicUrl}create-files`, requestOptions)
    ).json();
  } catch (e) {}
};

// add a file to internal
export const addFileRequestToInternal = async (
  key,
  parent_unique_id,
  name,
  token
) => {
  try {
    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "multipart/form-data");
    myHeaders.append("token", token);
    myHeaders.append("key", key);
    myHeaders.append("parent_unique_id", parent_unique_id);

    var formdata = new FormData();
    formdata.append("name", name, name.name);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
      // mode: "no-cors",
    };

    return await (
      await fetch(`${dynamicUrl}create-internal-file`, requestOptions)
    ).json();
  } catch (e) {}
};

// soft delete files & folder
export const softDelete = async (token, uniqueId, folderScope) => {
  try {
    const header = {
      token,
      uniqueId,
      folderScope,
    };
    const promise = await httpRequest.get("delete", { headers: header });
    return await promise.data;
  } catch (e) {}
};

// hard delete files & folder
export const hardDelete = async (token, uniqueId, key, folderScope) => {
  try {
    const header = {
      token,
      uniqueId,
      key,
      folderScope,
    };
    const promise = await httpRequest.get("trash", { headers: header });
    return await promise.data;
  } catch (e) {}
};

// restore files & folder
export const restoreData = async (token, uniqueId, folderScope) => {
  try {
    const header = {
      token,
      uniqueId,
      folderScope,
    };
    const promise = await httpRequest.get("restore", { headers: header });
    return await promise.data;
  } catch (e) {}
};

// rename folder
export const renameFolderName = async (
  token,
  uniqueId,
  name,
  type,
  folderScope
) => {
  try {
    const header = {
      token,
      uniqueId,
      name,
      type,
      folderScope,
    };
    const promise = await httpRequest.get("rename", { headers: header });
    return await promise.data;
  } catch (e) {}
};

// send message
export const sendImageAPI = async (token, name, path, previousMediaPath) => {
  try {
    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "multipart/form-data");
    myHeaders.append("token", token);
    myHeaders.append("path", path);
    myHeaders.append("previousMediaPath", previousMediaPath);

    var formdata = new FormData();
    formdata.append("msg", name, name.name);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
      // mode: "no-cors",
    };

    return await fetch(`${dynamicUrl}send-msg-media`, requestOptions);
  } catch (e) {}
};

// create a group
export const createAGroup = async (
  token,
  docid,
  name,
  my_id,
  sorted_item,
  conn_id,
  logo,
  type,
  scope,
  ticket_id
) => {
  try {
    const header = {
      token,
      "Content-Type": "application/json",
    };
    const promise = await httpRequest.post(
      "create-group",
      {
        docid,
        name,
        logo,
        created_by: my_id,
        user_ids: sorted_item,
        conn_id,
        type,
        scope,
        ticket_id,
      },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// create a room
export const createARoom = async (
  token,
  unique_id,
  is_billable,
  conn_id,
  billing_rate,
  callUser
) => {
  try {
    const header = { "Content-Type": "application/json", token };

    const promise = await httpRequest.post(
      "create-room",
      { unique_id, is_billable, conn_id, billing_rate, callUser },
      {
        headers: header,
      }
    );

    return await promise.data;
  } catch (e) {}
};

// disable a room
export const disableRoom = async (token, mstoken, room_name) => {
  try {
    const header = { "Content-Type": "application/json", token, mstoken };
    var data = JSON.stringify({
      name: room_name,
      active: false,
    });
    const promise = await httpRequest.post("status-room", data, {
      headers: header,
    });

    return await promise.data;
  } catch (e) {}
};

// create an appointment
export const createFullAppointment = async (
  token,
  conn_id,
  title,
  description,
  start_date,
  end_date,
  address,
  appoi_status,
  tasklist_arr,
  emp_arr,
  folder_name,
  folder_unique_id,
  folder_bookmark,
  ticket_id
) => {
  try {
    const header = { "Content-Type": "application/json", token };

    const promise = await httpRequest.post(
      "create-appointments",
      {
        conn_id,
        title,
        description,
        start_date,
        end_date,
        address,
        appoi_status,
        tasklist_arr,
        emp_arr,
        folder_name,
        folder_unique_id,
        folder_bookmark,
        ticket_id,
      },
      {
        headers: header,
      }
    );

    return await promise.data;
  } catch (e) {}
};

// create an vode conference appointment
export const createFullVideoConference = async (
  token,
  conn_id,
  title,
  description,
  start_date,
  end_date,
  address,
  appoi_status,
  tasklist_arr,
  emp_arr,
  folder_name,
  folder_unique_id,
  folder_bookmark,
  ticket_id,
  is_billable,
  billableConnId,
  billing_rate
) => {
  try {
    const header = { "Content-Type": "application/json", token };

    const promise = await httpRequest.post(
      "create-video-appointments",
      {
        conn_id,
        title,
        description,
        start_date,
        end_date,
        address,
        appoi_status,
        tasklist_arr,
        emp_arr,
        folder_name,
        folder_unique_id,
        folder_bookmark,
        ticket_id,
        is_billable,
        billableConnId,
        billing_rate,
      },
      {
        headers: header,
      }
    );

    return await promise.data;
  } catch (e) {}
};

// delete appointments
export const deleteAppointment = async (token, uniqueId) => {
  try {
    const header = { "Content-Type": "application/json", token, uniqueId };

    const promise = await httpRequest.get("delete-appointments", {
      headers: header,
    });

    return await promise.data;
  } catch (e) {}
};

// download a folder
export const downloadFolder = async (token, uniqueId, folderScope) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token,
      uniqueId,
      folderScope,
    };

    const promise = await httpRequest.get("download-folder", {
      headers: header,
    });

    return await promise.data;
  } catch (e) {}
};

// write a email
export const writeEmail = async (token, to, from, subject, bodydata) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("token", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      to,
      from,
      subject,
      bodydata,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return await fetch(`${dynamicUrl}send-mail`, requestOptions);
  } catch (e) {}
};

// delete notification
export const deleteNotification = async (token, notification_id) => {
  try {
    const header = { "Content-Type": "application/json", token };

    const promise = await httpRequest.post(
      "delete-notification",
      { notification_id },
      {
        headers: header,
      }
    );

    return await promise.data;
  } catch (e) {}
};

// clear all notification
export const clearAllNotification = async (token, type) => {
  try {
    const header = { "Content-Type": "application/json", token, type };

    const promise = await httpRequest.get("delete-all-notification", {
      headers: header,
    });

    return await promise.data;
  } catch (e) {}
};

// set notification status as read
export const setNotificationReadApi = async (token, type) => {
  try {
    const header = { token };
    const promise = await httpRequest.post(
      "set-notification-read",
      { type },
      {
        headers: header,
      }
    );
    return await promise;
  } catch (e) {}
};

// set device token for push notifications
export const setDevicePushNotificationTokenApi = async (
  user_id,
  device_token,
  device_token_set_unset,
  device_type
) => {
  try {
    const promise = await httpRequest.post("set-user-device-token", {
      user_id,
      device_token,
      device_token_set_unset,
      device_type,
    });

    return await promise.data;
  } catch (e) {}
};

// logout user
export const userLogout = async (token) => {
  try {
    const header = { "Content-Type": "application/json", token };

    const promise = await httpRequest.get("logout", {
      headers: header,
    });

    return await promise.data;
  } catch (e) {}
};

// set category as internal or external
export const changeCategoryScope = async (token, category_id, scope) => {
  try {
    const header = { "Content-Type": "application/json", token };

    const promise = await httpRequest.post(
      "update-category-scope",
      {
        category_id,
        scope,
      },
      {
        headers: header,
      }
    );

    return await promise.data;
  } catch (e) {}
};

// edit category name
export const editCategoryFunction = async (
  token,
  category_id,
  category_name
) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "update-category",
      { category_id, category_name },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// edit location name
export const editLocationFunction = async (token, locationId, locationName) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "rename-location",
      { locationId, locationName },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// create service ticket
export const createServiceTicket = async (
  token,
  ticketId,
  selectedOrg,
  selectedUser,
  severity,
  machineNo,
  desc,
  files,
  folderLink,
  folder_name,
  isConnected,
  requestorId,
  requestorOrgName,
  requestorOrgPhone,
  requestorName,
  requestorPhone,
  requestorEmail,
  raise_request_id
) => {
  try {
    const header = { "Content-Type": "application/json", token };

    const data = JSON.stringify({
      ticket_id: ticketId,
      requestor_org_id: selectedOrg,
      assign_tech: selectedUser,
      severity_issue: severity,
      machine_serial: machineNo,
      connect_folder: folderLink,
      connect_folder_id: folder_name,
      description: desc,
      files: files,
      is_connected: isConnected,
      requestor_id: requestorId,
      requestor_org_name: requestorOrgName,
      requestor_org_phone: requestorOrgPhone,
      requestor_name: requestorName,
      requestor_phone: requestorPhone,
      requestor_email: requestorEmail,
      raise_request_id,
    });

    const promise = await httpRequest.post("create-ticket", data, {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// create internal service ticket
export const createInternalServiceTicket = async (
  token,
  ticket_id,
  severity_issue,
  machine_serial,
  connect_folder,
  connect_folder_id,
  files,
  description,
  location,
  category_id,
  assign_tech,
  raise_request_id
) => {
  try {
    const header = { "Content-Type": "application/json", token };

    const data = JSON.stringify({
      ticket_id,
      severity_issue,
      machine_serial,
      connect_folder,
      connect_folder_id,
      description,
      files,
      location,
      category_id,
      assign_tech,
      raise_request_id,
    });

    const promise = await httpRequest.post("create-internal-ticket", data, {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// add a file to internal
export const addingMultipleFilesST = async (token, ticketId, files, status) => {
  try {
    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "multipart/form-data");
    myHeaders.append("token", token);
    myHeaders.append("ticketId", ticketId);
    myHeaders.append("fileStatus", status);
    var formdata = new FormData();

    for (let i in files) {
      formdata.append("file[]", files[i]);
    }
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
      // mode: "no-cors",
    };
    return await fetch(`${dynamicUrl}ticket-files`, requestOptions);
  } catch (e) {}
};

// update Severity of ticket
export const updateSeverityApi = async (token, severity_issue, ticket_id) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "update-severity-issue",
      { severity_issue, ticket_id },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// reassign tech
export const reassignTechApi = async (
  token,
  assign_tech,
  ticket_id,
  category_id
) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "reassign-ticket",
      { assign_tech, ticket_id, category_id },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// close ticket
export const closeServiceTicket = async (token, status, ticket_id) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "update-ticket-status",
      { status, ticket_id },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// add access for user
export const addUserAccess = async (
  token,
  management_type,
  permission,
  sub_management_type,
  emp_id,
  scope,
  location,
  team
) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "create_user_permissions",
      {
        management_type,
        permission,
        sub_management_type,
        emp_id,
        scope,
        location,
        team,
      },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// remove access for user
export const removeUserAccess = async (
  token,
  management_type,
  emp_id,
  scope,
  location,
  team
) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "remove-permissions",
      {
        management_type,
        emp_id,
        scope,
        location,
        team,
      },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// update access for user
export const updateUserAccess = async (
  token,
  management_type,
  permission,
  sub_management_type,
  emp_id,
  scope,
  location,
  team
) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "update-User-Permissions",
      {
        management_type,
        permission,
        sub_management_type,
        emp_id,
        scope,
        location,
        team,
      },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// raise service request
export const raiseServiceRequestApi = async (
  token,
  service_org_id,
  severity_issue,
  description,
  subject,
  connect_folder,
  connect_folder_id,
  location,
  category_id,
  requestType
) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "create-service-request",
      {
        service_org_id,
        severity_issue,
        description,
        subject,
        connect_folder,
        connect_folder_id,
        location,
        category_id,
        requestType,
      },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// api for addditional notes in service ticket
export const additionalNotesApi = async (token, ticket_id, severity_issue) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "create-ticket-notes",
      { ticket_id, severity_issue },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// api for addditional notes in service ticket
export const balanceTranscationApi = async (token, fetchData) => {
  try {
    let raw = JSON.stringify({
      stripeCustomerId: fetchData?.customer,
      paymentIntentId: fetchData?.id,
      amount: fetchData?.amount,
      description: fetchData?.description,
      metadata: fetchData?.metadata,
    });

    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post("balance-transaction", raw, {
      headers: header,
    });
    return await promise.data;
  } catch (e) {}
};

// contact us form
export const contactUsForm = async (
  token,
  username,
  userEmail,
  organization_name,
  content
) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "contact-us",
      { organization_name, username, content, userEmail },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// api for raising refund request
export const raiseRefundRequest = async (
  token,
  credit,
  requesteeId,
  transactionId,
  reason,
  balanceId
) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "send-refund-request",
      { credit, requesteeId, transactionId, reason, balanceId },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// api for raising refund request to super admin
export const raiseRefundRequestAdmin = async (token, refundId) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "send-refund-request-admin",
      { refundId },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// leaving call API
export const leaveCall = async (
  token,
  id,
  orgId,
  duration,
  name,
  isRoomActive,
  isAutoRoomDisable,
  leftAt,
  tabUniqueID,
  startAt
) => {
  try {
    const header = { "Content-Type": "application/json", token };
    var data = JSON.stringify({
      id,
      orgId,
      duration,
      name,
      isRoomActive,
      isAutoRoomDisable,
      leftAt,
      tabUniqueID,
      startAt,
    });
    const promise = await httpRequest.post("call-end", data, {
      headers: header,
    });

    return await promise.data;
  } catch (e) {}
};

// approve refund request
export const approveRefundRequest = async (token, refundId) => {
  try {
    const header = { "Content-Type": "application/json", token };
    var data = JSON.stringify({
      refundId,
    });
    const promise = await httpRequest.post("approve-refund", data, {
      headers: header,
    });

    return await promise.data;
  } catch (e) {}
};

// reject refund request
export const rejectRefundRequest = async (token, refundId) => {
  try {
    const header = { "Content-Type": "application/json", token };
    var data = JSON.stringify({
      refundId,
    });
    const promise = await httpRequest.post("reject-refund", data, {
      headers: header,
    });

    return await promise.data;
  } catch (e) {}
};

// reject refund request
export const purchaseSubscription = async (
  token,
  subscriptionType,
  amount,
  conn_id
) => {
  try {
    const header = { "Content-Type": "application/json", token };
    var data = JSON.stringify({
      subscriptionType,
      amount,
      conn_id,
    });
    const promise = await httpRequest.post("add-subscription", data, {
      headers: header,
    });

    return await promise.data;
  } catch (e) {}
};

// to unSubscribe a service
export const deletePurchasedSubscription = async (token, subscriptionId) => {
  try {
    const header = { "Content-Type": "application/json", token };
    var data = JSON.stringify({
      subscriptionId,
    });
    const promise = await httpRequest.post("subscription-delete", data, {
      headers: header,
    });

    return await promise.data;
  } catch (e) {}
};

// take action on transfer out requests
export const modifyTransferOutRequest = async (
  token,
  transferOutId,
  status,
  trn
) => {
  try {
    const header = { "Content-Type": "application/json", token };
    var data = JSON.stringify({
      transferOutId,
      status,
      trn,
    });
    const promise = await httpRequest.post("action-transfer-out", data, {
      headers: header,
    });

    return await promise.data;
  } catch (e) {}
};

// create Amnius Admin
export const createAmniusAdmin = async (
  token,
  userName,
  email,
  organizationName
) => {
  try {
    let tmz = moment.tz.guess();
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "create-amnius-admin",
      { userName, email, organizationName, customTimezone: tmz },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// modify portfolio and workbook
export const modifyPortfolioAndWorkBook = async (
  token,
  portfolioId,
  type,
  value
) => {
  try {
    let tmz = moment.tz.guess();
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "update-portfolio-scope",
      { portfolioId, type, value },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// add a file
export const addPortfolioAndWorkbookFiles = async (
  token,
  name,
  classify,
  parent_unique_id,
  key,
  toastId
) => {
  try {
    const header = {
      token: token,
      classify: classify,
      parent_unique_id: parent_unique_id,
      key: key,
    };

    var formdata = new FormData();
    formdata.append("name", name, name.name);

    const promise = await httpRequest.post("create-portfolio-file", formdata, {
      headers: header,
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        if (!toastId.current) {
          toastId.current = toast(
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label>
                {Math.round((100 * data.loaded) / data.total)}% Uploaded
              </label>
              <label style={{ fontSize: "11px" }}>
                Do not Refresh the Page while it uploads
              </label>
            </div>,
            {
              autoClose: false,
              type: "info",
            }
          );
        } else {
          toast.update(toastId.current, {
            render: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label>
                  {Math.round((100 * data.loaded) / data.total)}% Uploaded
                </label>
                <label style={{ fontSize: "11px" }}>
                  {Math.round((100 * data.loaded) / data.total) === 100
                    ? "Almost Done. Please Wait..."
                    : "Do not Refresh the Page while it uploads"}
                </label>
              </div>
            ),
            type: "info",
            autoClose: false,
          });
        }
      },
    });
    return await promise.data;
  } catch (e) {}
};

// add a file
export const replacePortfolioFiles = async (token, uniqueId, name, toastId) => {
  try {
    const header = {
      token,
      uniqueId,
    };

    var formdata = new FormData();
    formdata.append("name", name, name.name);

    const promise = await httpRequest.post("replace-portfolio-file", formdata, {
      headers: header,
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        if (!toastId.current) {
          toastId.current = toast(
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label>
                {Math.round((100 * data.loaded) / data.total)}% Uploaded
              </label>
              <label style={{ fontSize: "11px" }}>
                Do not Refresh the Page while it uploads
              </label>
            </div>,
            {
              autoClose: false,
              type: "info",
            }
          );
        } else {
          toast.update(toastId.current, {
            render: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label>
                  {Math.round((100 * data.loaded) / data.total)}% Uploaded
                </label>
                <label style={{ fontSize: "11px" }}>
                  {Math.round((100 * data.loaded) / data.total) === 100
                    ? "Almost Done. Please Wait..."
                    : "Do not Refresh the Page while it uploads"}
                </label>
              </div>
            ),
            type: "info",
            autoClose: false,
          });
        }
      },
    });
    return await promise.data;
  } catch (e) {}
};

// approve reject organisation
export const approveRejectOrganisation = async (
  org_id,
  approveStatus,
  notificationId,
  token
) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const body = JSON.stringify({
      org_id,
      approveStatus,
      notificationId,
    });
    const data = await httpRequest.post("admin/approve-reject-org", body, {
      headers: header,
    });
    return await data;
  } catch (e) {}
};

// super-admin api to transfer credits to any organisation
export const tranferCreditsToOrganisation = async (
  token,
  org_id,
  transferAmount
) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const body = JSON.stringify({
      org_id,
      transferAmount,
    });

    const promise = await httpRequest.post(
      "admin/superadmin-transfer-credits",
      body,
      { headers: header }
    );
    return await promise;
  } catch (e) {}
};

// move Folder
export const moveFolder = async (
  token,
  conn_id,
  sourceUniqueId,
  destinationUniqueId
) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "move-folder",
      { conn_id, sourceUniqueId, destinationUniqueId },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// purchase storage plan
export const purchaseStoragePlan = async (token, planId) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "purchase-storage-plan",
      { planId },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// create storage plan
export const createStoragePlan = async (
  token,
  storageName,
  fromSize,
  toSize,
  rate
) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "admin/create-storage-plan",
      { storageName, fromSize, toSize, rate },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// update storage plan
export const updateStoragePlan = async (
  token,
  storageName,
  fromSize,
  toSize,
  rate,
  planId
) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "admin/update-storage-plan",
      { storageName, fromSize, toSize, rate, planId },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// delete storage plan
export const deleteStoragePlan = async (token, planId) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "admin/delete-storage-plan",
      { planId },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// send subscription Request
export const sendSubscriptionRequest = async (token, type, conn_id) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token,
    };
    const data = await httpRequest.post(
      "send-subscription-request",
      { type, conn_id },
      { headers: header }
    );
    return await data;
  } catch (e) {}
};

// approve or reject portfolio request
export const approveRejectRequest = async (
  token,
  action,
  subscriptionRequestId,
  notificationId
) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token,
    };
    const data = await httpRequest.post(
      "action-subscription-request",
      { action, subscriptionRequestId, notificationId },
      { headers: header }
    );
    return await data;
  } catch (e) {}
};

// delete ticket files
export const deletingTicketFiles = async (token, files) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token,
    };
    const data = await httpRequest.post(
      "delete-ticket-files",
      { key: files },
      { headers: header }
    );
    return await data;
  } catch (e) {}
};

// internal request file upload
export const raiseRequestFileUpload = async (
  token,
  requestServiceId,
  files
) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("token", token);
    myHeaders.append("requestServiceId", requestServiceId);
    var formdata = new FormData();
    for (let i in files) {
      formdata.append("file[]", files[i]);
    }
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    return await fetch(`${dynamicUrl}request-service-files`, requestOptions);
  } catch (e) {}
};

// move portfolio
export const movePortfolio = async (token, parent_unique_id, unique_id) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "move-portfolio",
      { parent_unique_id, unique_id },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// delete portfolioVideo notifications
export const deletePortfolioVideoRateNotification = async (token, folder) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "delete-notification-folderKey",
      { folder },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// update an appointment
export const updateAppointment = async (
  token,
  conn_id,
  title,
  description,
  start_date,
  end_date,
  address,
  appoi_status,
  tasklist_arr,
  emp_arr,
  folder_name,
  folder_unique_id,
  folder_bookmark,
  ticket_id,
  is_billable,
  billableConnId,
  unique_id,
  type,
  billing_rate
) => {
  try {
    const header = { "Content-Type": "application/json", token };

    const promise = await httpRequest.post(
      "update-appointments",
      {
        conn_id,
        title,
        description,
        start_date,
        end_date,
        address,
        appoi_status,
        tasklist_arr,
        emp_arr,
        folder_name,
        folder_unique_id,
        folder_bookmark,
        ticket_id,
        is_billable,
        billableConnId,
        unique_id,
        type,
        billing_rate,
      },
      {
        headers: header,
      }
    );

    return await promise.data;
  } catch (e) {}
};

// send chat notifications
export const sendChatNotification = async (
  token,
  title,
  body,
  seenData,
  docId,
  sendBy,
  sendTo,
  chatType,
  ticketId,
  isGroup
) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "chat-push-notification",
      {
        title,
        body,
        seenData,
        docId,
        sendBy,
        sendTo,
        chatType,
        ticketId,
        isGroup,
      },
      { headers: header }
    );
    return await promise.data;
  } catch (e) {}
};

// share external folder
export const shareExternalFolders = async (
  token,
  uniqueid,
  destinationUniqueId
) => {
  try {
    const header = { "Content-Type": "application/json", token };
    const promise = await httpRequest.post(
      "shared-internal-folder",
      {
        uniqueid,
        destinationUniqueId,
      },
      {
        headers: header,
      }
    );
    return await promise.data;
  } catch (e) {}
};
