import EditProfile from "../PureComponents/EditProfile";
import React from "react";

const RenderEditProfile = ({ currEle, popup, hide }) => {
  return (
    <>
      <EditProfile currEle={currEle} popup={popup} hide={hide} />
    </>
  );
};

export default RenderEditProfile;
