import "./RenamePopup.css";

import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";

import PostAddIcon from "@mui/icons-material/PostAdd";

const RenamePopup = ({ verify, hide, type, currentName }) => {
  const [text, setText] = useState(currentName);
  const handleSubmit = (e) => {
    e.preventDefault();
    verify(text);
    hide(false);
  };

  return (
    <>
      <nav className="rename-container">
        <div className="rename-popup">
          <div className="rename-cross" onClick={() => hide(false)}>
            X
          </div>
          {type?.toString() === "rename" ? (
            <span>Add New Name:</span>
          ) : (
            <span>Add Second Name:</span>
          )}
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="rename-email">
              <TextField
                fullWidth
                required
                autoFocus
                value={text}
                label="Rename"
                variant="outlined"
                type="text"
                onChange={(e) => setText(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PostAddIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="rename-submit">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ background: "#446fa2" }}
              >
                SUBMIT
              </Button>
            </div>
          </form>
        </div>
      </nav>
    </>
  );
};

export default RenamePopup;
