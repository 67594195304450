import { alertReducer, newChatAlertReducer } from "./alert.reducer";
import {
  appointmentDashboardReducer,
  renderChatProfileReducer,
  renderGeneralInfo,
  renderGroupDataReducer,
  renderOrganizationInfoReducer,
  renderRecentChatUserReducer,
  servicePageTypeReducer,
  ticketChatDataReducer,
} from "./render.reducer";
import {
  currentFolderAutomationReducer,
  currentVisitOrgReducer,
  globalOptionButtonReducer,
  hashIdReducer,
  notConnectedAmniusReducer,
  raiseRequestDataReducer,
  raiseRequestIdReducer,
  selectedOrganizationSTReducer,
  ticketIdReducer,
  userListReducer,
} from "./variable.reducer";
import {
  getEventListAppointmentReducer,
  getEventListReducer,
  getEventListVideoConferenceReducer,
  getFolderDataReducer,
  getFullNotificationReducer,
  getOrganizationStorageDataReducer,
  getRecentNotificationCountReducer,
  getRecentNotificationReducer,
  getStripeCreditBalanceReducer,
  getTrashDataReducer,
  getUsableCreditBalanceReducer,
  internalFolderDataReducer,
  selectedFileDataReducer,
  selectedFolderDataReducer,
  selectedFolderRootDataReducer,
  setClientSecretKeyReducer,
  storeVideoChatLinkReducer,
} from "./get.reducer";

import { USER_LOGOUT } from "Config/ActionType/action.type";
import { authenticationReducer } from "./authentication.reducer";
import { combineReducers } from "redux";
import { dialogReducer } from "./dialog.reducer";
import storage from "redux-persist/lib/storage";

const appReducers = combineReducers({
  newChatAlertReducer,
  alertReducer,
  dialogReducer,
  authenticationReducer,
  renderGeneralInfo,
  renderOrganizationInfoReducer,
  appointmentDashboardReducer,
  getRecentNotificationCountReducer,
  getRecentNotificationReducer,
  getFullNotificationReducer,
  getFolderDataReducer,
  selectedFileDataReducer,
  selectedFolderDataReducer,
  selectedFolderRootDataReducer,
  internalFolderDataReducer,
  currentVisitOrgReducer,
  globalOptionButtonReducer,
  getTrashDataReducer,
  hashIdReducer,
  renderChatProfileReducer,
  userListReducer,
  renderRecentChatUserReducer,
  renderGroupDataReducer,
  storeVideoChatLinkReducer,
  getEventListReducer,
  getEventListAppointmentReducer,
  getEventListVideoConferenceReducer,
  currentFolderAutomationReducer,
  servicePageTypeReducer,
  selectedOrganizationSTReducer,
  notConnectedAmniusReducer,
  ticketIdReducer,
  ticketChatDataReducer,
  raiseRequestIdReducer,
  raiseRequestDataReducer,
  setClientSecretKeyReducer,
  getStripeCreditBalanceReducer,
  getUsableCreditBalanceReducer,
  getOrganizationStorageDataReducer,
});

const rootReducers = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
    storage.removeItem("persist:root");
    sessionStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("deviceTokenForPushNotification");
    localStorage.removeItem("filePath");
    localStorage.removeItem("fileType");
  }
  return appReducers(state, action);
};

export default rootReducers;
