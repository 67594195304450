import "./FileViewer.css";

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Document, Page, pdfjs } from "react-pdf";
import React, { memo, useEffect, useState } from "react";

import DXFFileViewer from "./DXFFileViewer";
import Iframe from "react-iframe";
import STEPFileViewer from "./STEPFileViewer";
import STLFileViewer from "./STLFileViewer";

const FileViewerComponent = ({ disableHeader, path, type, folderType }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  let filePath = path ? path : localStorage.getItem("filePath");
  const fileType = type ? type : localStorage.getItem("fileType");
  const imageFileTypeArray = ["jpg", "png", "jpeg"];
  const videoFileTypeArray = ["mp4", "mkv", "webm"];
  const cadFileTypeArray = ["step", "stp"];

  const isPdf = fileType?.toLowerCase()?.toString() === "pdf";
  const isVideo = videoFileTypeArray.includes(
    fileType?.toLowerCase()?.toString()
  );
  const isImage = imageFileTypeArray.includes(
    fileType?.toLowerCase()?.toString()
  );
  const isCADSTEP = cadFileTypeArray.includes(
    fileType?.toLowerCase()?.toString()
  );
  const isCADSTL = fileType?.toLowerCase()?.toString() === "stl";
  const isCADDXF = fileType?.toLowerCase()?.toString() === "dxf";
  const isHeaderDisabled = disableHeader || false;

  if (folderType === "webLink") {
    if (path?.includes("youtube.com") && path?.includes("watch?v="))
      filePath = path?.replace("watch?v=", "embed/");
    if (path?.includes("youtu.be")) {
      const code = path?.split("/").pop();
      filePath = `https://www.youtube.com/embed/${code}`;
    }
  }

  const [numPages, setNumPages] = useState(null);

  const docs = [{ uri: filePath, fileType: fileType }];

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div
      className="file-view-documents"
      style={{
        display: isVideo && "flex",
        justifyContent: isVideo && "center",
      }}
    >
      {folderType === "webLink" ? (
        <Iframe
          url={filePath}
          width="100%"
          height="100%"
          id="filePath"
          frameBorder={0}
          display="block"
          position="relative"
        />
      ) : isPdf ? (
        <div className="pdf-viewer-container">
          <Document file={filePath} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      ) : isVideo ? (
        <video
          src={filePath}
          style={{
            width: "95%",
            maxWidth: "1300px",
          }}
          autoPlay
          controls
          controlsList="nodownload"
        ></video>
      ) : isCADSTEP ? (
        <STEPFileViewer fileUrl={filePath} />
      ) : isCADSTL ? (
        <STLFileViewer fileUrl={filePath} />
      ) : isCADDXF ? (
        <DXFFileViewer fileUrl={filePath} />
      ) : (
        <>
          <DocViewer
            documents={docs}
            pluginRenderers={DocViewerRenderers}
            config={{
              header: {
                disableHeader: isHeaderDisabled,
                disableFileName: isHeaderDisabled,
                retainURLParams: isHeaderDisabled,
              },
            }}
          />
          {!isImage && <div className="empty-container" />}
        </>
      )}
    </div>
  );
};

export default memo(FileViewerComponent);
