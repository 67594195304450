import "./EditProfile.css";

import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { createLocationCategory, sendImageAPI } from "Config/API/action";
import {
  getCategoryData,
  getGeneralInfo,
  getLocationData,
} from "Config/API/get";
import { useDispatch, useSelector } from "react-redux";

import ApiLoader from "Components/Loader/ApiLoader";
import CloseIcon from "@mui/icons-material/Close";
import DateInput from "Components/DateInput/DateInput";
import StorageLimitReachedPopup from "Components/Popup/StorageLimitReachedPopup/StorageLimitReachedPopup";
import formValidators from "Config/Validators/formValidators";
import { renderGeneralInfo } from "Redux/action/render.action";
import { toast } from "react-toastify";
import { updateUser } from "Config/API/update";
import useFullPageLoader from "Hooks/useFullPageLoader";
import useIsStorageLimitReached from "Hooks/useIsStorageLimitReached";

const EditProfile = ({ currEle, popup, hide }) => {
  const dispatch = useDispatch();
  const isStorageLimitReached = useIsStorageLimitReached();
  const my_id = useSelector((state) => state.authenticationReducer?.id);
  const general_info = useSelector((state) => state.renderGeneralInfo);
  const logRole = useSelector((state) => state.authenticationReducer.role);
  const [showStorageLimitReachedPopup, setShowStorageLimitReachedPopup] =
    useState(false);
  const [category_list, setCategory_list] = useState([
    { category_id: currEle?.category },
  ]);
  const [locationId, setLocationId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [location_list, setLocation_list] = useState([
    { location: currEle?.location },
  ]);
  const [showAddUserDataModal, setShowAddUserDataModal] = useState(false);
  const [modalData, setModalData] = useState({
    placeholderText: "",
    type: "",
    data: "",
  });

  const [location, setLocation] = useState("");
  const [userFirstName, setUserFirstName] = useState(currEle?.firstname || "");
  const [userLastName, setUserLastName] = useState(currEle?.lastname || "");
  const [email, setEmail] = useState(currEle?.email ? currEle.email : "");
  const [designation, setDesignation] = useState(
    currEle?.designation ? currEle.designation : ""
  );
  const [category, setCategory] = useState("");
  const [scope, setScope] = useState(
    currEle?.scope ? currEle.scope : "noscope"
  );
  const [mobile, setMobile] = useState(currEle?.phone ? currEle.phone : "");
  const [officeNumber, setOfficeNumber] = useState(
    currEle?.office_phone ? currEle.office_phone : ""
  );
  const [extension, setExtension] = useState(
    currEle?.extension ? currEle.extension : ""
  );
  const [password, setPassword] = useState("");
  const [image, setImage] = useState(currEle?.image ? currEle.image : "");
  const emp_id = currEle?.id ? currEle.id : "";
  const org_id = currEle?.org_id ? currEle.org_id : "";
  const role = currEle?.role ? currEle.role : "";
  const token = localStorage.getItem("token");
  const [locationLoading, setLocationLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [loader, setLoading, resetLoading] = useFullPageLoader();

  // validators
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [selectedDay, setSelectedDay] = useState(
    currEle?.dob ? currEle.dob.split("/")[1] : ""
  );
  const [selectedMonth, setSelectedMonth] = useState(
    currEle?.dob ? currEle.dob.split("/")[0] : ""
  );
  const [selectedYear, setSelectedYear] = useState(
    currEle?.dob ? currEle.dob.split("/")[2] : ""
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(formValidators(mobile, email, officeNumber));
    setIsSubmit(true);
  };

  // we used useEffect so that we get updatedState
  useEffect(() => {
    if (Object.keys(formErrors).length?.toString() === "0" && isSubmit) {
      let dob;
      if (selectedMonth && selectedDay && selectedYear) {
        dob = `${selectedMonth}/${selectedDay}/${selectedYear}`;
      } else {
        dob = "";
      }
      const username = `${userFirstName} ${userLastName}`;
      const update_user_status = updateUser(
        emp_id,
        username,
        userFirstName,
        userLastName,
        email,
        mobile,
        officeNumber,
        extension,
        designation,
        password,
        image,
        org_id,
        categoryId || "",
        scope,
        dob || "",
        locationId || "",
        token
      );
      update_user_status
        .then((res) => {
          if (res?.status) {
            toast(res?.message, {
              type: "success",
            });
            if (
              role?.toString() === "admin" ||
              role?.toString() === "super_admin"
            ) {
              // general info rendering
              const received_info = getGeneralInfo(token);
              received_info.then((response) => {
                if (response?.status) {
                  dispatch(renderGeneralInfo(response?.org_info));
                }
              });

              setTimeout(() => {
                hide(false);
              }, [1000]);
            } else {
              // updated team rendering
              window.location.reload();
            }
          } else {
            toast(res?.message, {
              type: "error",
            });
          }
        })
        .catch(() =>
          toast("Failed! Try again later", {
            type: "error",
          })
        );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  useEffect(() => {
    if (popup) getAllUserLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popup]);

  useEffect(() => {
    if (popup && locationId !== "") getAllCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  const getAllUserLocations = () => {
    setLocationLoading(true);
    const locationList = getLocationData(token, general_info?.id);
    locationList.then((res) => {
      setLocationLoading(false);
      if (res?.location?.length > 0) {
        const locationInfo = res?.location?.filter(
          (item) => item?.id === currEle?.location
        )[0];
        setLocation(locationInfo?.location);
        setLocationId(locationInfo?.id);
        setLocation_list(res?.location);
      }
    });
  };

  const getAllCategory = () => {
    setCategoryLoading(true);
    const categoryList = getCategoryData(token, general_info?.id, locationId);
    categoryList.then((res) => {
      setCategoryLoading(false);
      const categoryInfo = res?.category?.filter(
        (item) => item?.id === currEle?.category
      )[0];
      setCategory(categoryInfo?.category_id);
      setCategoryId(categoryInfo?.id);
      setCategory_list(res?.category);
    });
  };

  const addUserDataModal = () => {
    const save = (e) => {
      e.preventDefault();
      switch (modalData.type) {
        case "location": {
          const res = createLocationCategory(
            token,
            modalData.type,
            modalData.data,
            ""
          );
          res
            .then((data) => {
              if (data?.status) {
                if (data?.message === "Location Already Exist.")
                  return toast(data?.message, { type: "info" });
                toast(data?.message, {
                  type: "success",
                });
                setShowAddUserDataModal(false);
                setModalData({
                  placeholderText: "",
                  type: "",
                  data: "",
                });
                getAllUserLocations();
              } else {
                toast("Failed! Try again later", { type: "error" });
              }
            })
            .catch((e) => {
              toast("Failed! Try again later", { type: "error" });
            });
          break;
        }
        case "category": {
          const res = createLocationCategory(
            token,
            modalData.type,
            modalData.data,
            locationId
          );
          res
            .then((data) => {
              if (data?.status) {
                if (data?.message === "Category Already Exist.")
                  return toast(data?.message, { type: "info" });
                toast(data?.message, {
                  type: "success",
                });
                setShowAddUserDataModal(false);
                setModalData({
                  placeholderText: "",
                  type: "",
                  data: "",
                });
                getAllCategory();
              } else {
                toast("Failed! Try again later", { type: "error" });
              }
            })
            .catch((e) => {
              toast("Failed! Try again later", { type: "error" });
            });
          break;
        }
        default:
          break;
      }
    };
    return (
      <div className="add-user-data-modal-wrapper">
        <form onSubmit={save} autoComplete="off">
          <TextField
            fullWidth
            required
            type="text"
            placeholder={modalData.placeholderText}
            label={modalData.placeholderText}
            onChange={(e) =>
              setModalData({ ...modalData, data: e.target.value })
            }
          />
          <Button
            className="save-button"
            type="submit"
            variant="contained"
            style={{ background: "#446FA2" }}
          >
            Save {modalData?.type}
          </Button>
        </form>
        <button
          className="closebutton"
          onClick={() => {
            setShowAddUserDataModal(false);
            setModalData({
              placeholderText: "",
              type: "",
              data: "",
            });
          }}
        >
          <CloseIcon />
        </button>
      </div>
    );
  };

  const handleClose = () => {
    hide(false);
    setLocation("");
    setCategory("");
    setLocationId("");
    setCategoryId("");
    setLocation_list([]);
    setCategory_list([]);
  };

  return popup ? (
    <>
      <div className="edit-profile-container">
        <div>
          <div className="edit-profile-top-wrapper">
            <div className="edit-profile-head">Edit Profile</div>
            <div className="edit-profile-close">
              <IconButton onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          <div className="edit-profile-form">
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="edit-profile-name">
                <TextField
                  required
                  label="Update First Name"
                  variant="outlined"
                  value={userFirstName}
                  style={{ width: "49%" }}
                  type="text"
                  onChange={(e) => setUserFirstName(e.target.value)}
                />
                <TextField
                  required
                  label="Update Last Name"
                  variant="outlined"
                  value={userLastName}
                  style={{ width: "49%" }}
                  type="text"
                  onChange={(e) => setUserLastName(e.target.value)}
                />
              </div>

              <div className="edit-profile-designation">
                <TextField
                  fullWidth
                  required
                  label="Update Designation"
                  variant="outlined"
                  value={designation}
                  type="text"
                  onChange={(e) => setDesignation(e.target.value)}
                  style={{ marginBlock: "20px" }}
                />
              </div>

              {/* Explaintion: currEle.role decide only employee card and logRole decide only admin edit their category and scope */}

              {currEle?.role?.toString() !== "admin" &&
              logRole?.toString() === "admin" ? (
                <>
                  <div className="add-user-location">
                    <div className="add-user-location-inner-wrapper">
                      <FormControl style={{ margin: "10px 0" }}>
                        <InputLabel id="select-location">
                          Select Location
                        </InputLabel>
                        <Select
                          required
                          value={location}
                          labelId="select-location"
                          label="Select Location"
                          onChange={(e) => {
                            setLocation(e.target.value);
                            setCategory("");
                          }}
                        >
                          {!locationLoading ? (
                            location_list?.length > 0 ? (
                              location_list?.map((currEle, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    onClick={() => setLocationId(currEle?.id)}
                                    value={currEle?.location}
                                  >
                                    {currEle?.location}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <MenuItem disabled>
                                Add location to choose one
                              </MenuItem>
                            )
                          ) : (
                            <MenuItem
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                height: "100px",
                              }}
                            >
                              <ApiLoader
                                height="35px"
                                width="35px"
                                borderWidth="6px"
                                borderTopWidth="6px"
                              />
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      <button
                        onClick={() => {
                          setShowAddUserDataModal(true);
                          setModalData({
                            placeholderText: "Add Location",
                            type: "location",
                          });
                        }}
                        type="button"
                        style={{ margin: "10px 0" }}
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <div
                    className="add-profile-category-inner-wrapper"
                    style={{ margin: "20px 0" }}
                  >
                    <FormControl>
                      <InputLabel id="select-category">
                        Select Category
                      </InputLabel>
                      <Select
                        required
                        value={category}
                        labelId="select-category"
                        label="Select Category"
                        onChange={(e) => {
                          setCategory(e.target.value);
                        }}
                      >
                        {!categoryLoading ? (
                          category_list?.length > 0 ? (
                            category_list?.map((currEle, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={currEle?.category_id}
                                  onClick={() => setCategoryId(currEle?.id)}
                                >
                                  {currEle?.category_id}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <MenuItem disabled>
                              Add category to choose one
                            </MenuItem>
                          )
                        ) : (
                          <MenuItem
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              height: "100px",
                            }}
                          >
                            <ApiLoader
                              height="35px"
                              width="35px"
                              borderWidth="6px"
                              borderTopWidth="6px"
                            />
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <button
                      onClick={() => {
                        if (locationId === "" || !locationId)
                          return toast("Please select a location first", {
                            type: "info",
                          });
                        setShowAddUserDataModal(true);
                        setModalData({
                          placeholderText: "Add Category",
                          type: "category",
                        });
                      }}
                      type="button"
                    >
                      +
                    </button>
                  </div>

                  <div className="edit-user-scope">
                    <label>Select Scope:</label>
                    <select
                      required
                      defaultValue={scope}
                      onChange={(e) => {
                        setScope(e.target.value);
                      }}
                    >
                      <option value="noscope" disabled>
                        -- Select an option --
                      </option>
                      <option value="internal">Internal</option>
                      <option value="external">External</option>
                    </select>
                  </div>
                  <div
                    className="add-user-birthday"
                    style={{ marginTop: "20px" }}
                  >
                    <label>Birthday:</label>
                    <>
                      <DateInput
                        selectedDay={selectedDay}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                        setSelectedDay={setSelectedDay}
                        setSelectedMonth={setSelectedMonth}
                        setSelectedYear={setSelectedYear}
                      />
                    </>
                  </div>
                </>
              ) : null}

              <div className="edit-profile-email">
                <TextField
                  fullWidth
                  required
                  label="Update Email"
                  variant="outlined"
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ marginTop: "20px" }}
                />
                {formErrors.email ? (
                  <p
                    className="error"
                    style={{ marginTop: "5px", marginBottom: 0 }}
                  >
                    {formErrors.email}
                  </p>
                ) : null}
              </div>

              <div className="edit-profile-number">
                <TextField
                  fullWidth
                  label="Update Mobile Number"
                  variant="outlined"
                  value={mobile}
                  type="number"
                  onChange={(e) => setMobile(e.target.value)}
                  style={{ marginTop: "20px" }}
                />
                {formErrors.phone ? (
                  <p
                    className="error"
                    style={{ marginTop: "5px", marginBottom: 0 }}
                  >
                    {formErrors.phone}
                  </p>
                ) : null}
              </div>
              <div className="edit-profile-office">
                <div>
                  <TextField
                    fullWidth
                    label="Update Office Number"
                    variant="outlined"
                    value={officeNumber}
                    type="number"
                    onChange={(e) => setOfficeNumber(e.target.value)}
                    style={{ marginTop: "20px" }}
                  />
                  {formErrors.office ? (
                    <p
                      className="error"
                      style={{ marginTop: "5px", marginBottom: 0 }}
                    >
                      {formErrors.office}
                    </p>
                  ) : null}
                </div>
                <TextField
                  fullWidth
                  label="Update Extension"
                  variant="outlined"
                  value={extension}
                  type="number"
                  onChange={(e) => setExtension(e.target.value)}
                  style={{ marginTop: "20px" }}
                />
              </div>
              <div className="edit-profile-password">
                <TextField
                  fullWidth
                  label="Update Password"
                  variant="outlined"
                  type="text"
                  onChange={(e) => setPassword(e.target.value)}
                  style={{ marginTop: "20px" }}
                />
              </div>
              <div className="edit-profile-logo">
                <label htmlFor="update-profile-logo">
                  Update Profile Image (Recommended Ratio - 1:1)
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="update-profile-logo"
                  onChange={(e) => {
                    const size = e.target.files[0]?.size;
                    if (isStorageLimitReached(size))
                      return setShowStorageLimitReachedPopup(true);
                    if (size > 10000000) {
                      toast("File size excceded 10MB", {
                        type: "info",
                      });
                    } else {
                      setLoading();
                      const image_status = sendImageAPI(
                        token,
                        e.target.files[0],
                        `profile/${my_id}`,
                        currEle?.image ? currEle?.image : ""
                      );
                      image_status
                        .then((res) => {
                          if (res?.status) {
                            res?.json().then((response) => {
                              resetLoading();
                              if (response?.status) {
                                if (response?.message?.includes("limit"))
                                  return toast(response?.message, {
                                    type: "info",
                                  });
                                setImage(response?.data);
                              } else {
                                toast(response?.message, { type: "error" });
                              }
                            });
                          } else {
                            toast("Failed! Try again later", {
                              type: "error",
                            });
                          }
                        })
                        .catch(() =>
                          toast("Failed! Try again later", {
                            type: "error",
                          })
                        );
                    }
                  }}
                />
              </div>
              <Button
                className="form-button"
                type="submit"
                variant="contained"
                style={{ background: "#446FA2" }}
              >
                Save Changes
              </Button>
            </form>
          </div>
        </div>
      </div>
      {loader}
      {showAddUserDataModal && (
        <div className="add-user-data-modal-main"> {addUserDataModal()} </div>
      )}
      <div style={{ position: "absolute", left: 0 }}>
        {showStorageLimitReachedPopup && (
          <StorageLimitReachedPopup hide={setShowStorageLimitReachedPopup} />
        )}
      </div>
    </>
  ) : null;
};

export default EditProfile;
