import "./STRaiseRequestFormShow.css";

import React, { useEffect, useState } from "react";
import {
  notConnectedAmniusAction,
  raiseRequestDataAction,
  selectedOrganizationSTAction,
} from "Redux/action/variable.action";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "@mui/material";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { Link } from "react-router-dom";
import excelIcon from "Media/icons/simple-microsoftexcel.svg";
import { getRaiseRequestData } from "Config/API/get";
import imageIcon from "Media/icons/simple-image.png";
import officeIcon from "Media/icons/simple-microsoftword.svg";
import pdfIcon from "Media/icons/simple-pdf.svg";
import unknownFile from "Media/icons/unknownFile.png";
import useFullPageLoader from "Hooks/useFullPageLoader";
import { useNavigate } from "react-router-dom";
import videoFile from "Media/icons/videoFile.png";

const STRaiseRequestFormShow = () => {
  const token = localStorage.getItem("token");
  const service_org_id = useSelector((state) => state.raiseRequestIdReducer);
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // fields to fill
  const [requestorOrganization, setRequestorOrganization] = useState("");
  // const [requestorOrgPhone, setRequestorOrgPhone] = useState("None");
  const [requestorName, setRequestorName] = useState("");
  const [requestorPhone, setRequestorPhone] = useState("");
  const [requestorEmail, setRequestorEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [completeData, setCompleteData] = useState("");
  const [folderName, setFolderName] = useState("");
  const [files, setFiles] = useState([]);
  const [requestType, setRequestType] = useState("");
  const [teamData, setTeamData] = useState("");
  const [location, setLocation] = useState("");
  useEffect(() => {
    setLoader();
    const raise_request_status = getRaiseRequestData(token, service_org_id);
    raise_request_status.then((res) => {
      if (res?.data) {
        resetLoader();
        setCompleteData(res?.data?.requestService[0]);
        setRequestorOrganization(
          res?.data?.requestService[0]?.requestor?.organization_name || "None"
        );
        setRequestorName(
          res?.data?.requestService[0]?.requestor?.username || "None"
        );
        setRequestorPhone(
          res?.data?.requestService[0]?.requestor?.phone || "None"
        );
        setRequestorEmail(
          res?.data?.requestService[0]?.requestor?.email || "None"
        );
        setSubject(res?.data?.requestService[0]?.subject || "None");
        setDescription(res?.data?.requestService[0]?.description || "None");
        setFolderName(res?.data?.requestService[0]?.connect_folder_name || "");
        setFiles(res?.data?.requestService[0]?.files || "");
        setRequestType(res?.data?.requestService[0]?.requestType || "");
        setTeamData(res?.data?.requestService[0]?.categoryName || "");
        setLocation(res?.data?.requestService[0]?.locationName || "");
      }
    });
  }, []);

  return (
    <>
      <div className="straisereqshow-parent">
        <div className="straisereqshow-main">
          <form className="straisereqshow-form-body" autoComplete="off">
            <div className="straisereqshow-heading">
              <p>Service Request Form</p>
            </div>
            <div className="straisereqshow-wrapper">
              {requestorOrganization?.toString() !== "" ? (
                <div className="straisereqshow-fields">
                  <label htmlFor="organization">
                    Requestor Organization<span>*</span>:
                  </label>

                  <input
                    style={{ backgroundColor: "#f1f2f6" }}
                    type="text"
                    id="organization"
                    value={requestorOrganization}
                    readOnly
                    required
                  />
                </div>
              ) : (
                <div className="straisereqshow-fields">
                  <label htmlFor="organization">
                    Requestor Organization<span>*</span>:
                  </label>

                  <input
                    type="text"
                    id="organization"
                    style={{ backgroundColor: "#f1f2f6" }}
                    value={requestorOrganization}
                    onChange={(e) => {
                      setRequestorOrganization(e.target.value);
                    }}
                    required
                  />
                </div>
              )}

              <div className="straisereqshow-fields">
                <label htmlFor="contact-name">
                  Requestor Name<span>*</span>:
                </label>
                <input
                  type="text"
                  value={requestorName}
                  id="contact-name"
                  style={{ backgroundColor: "#f1f2f6" }}
                  readOnly
                  required
                />
              </div>

              <div className="straisereqshow-fields">
                <label htmlFor="contact-email">
                  Requestor Email<span>*</span>:
                </label>
                <input
                  type="text"
                  value={requestorEmail}
                  id="contact-email"
                  style={{ backgroundColor: "#f1f2f6" }}
                  readOnly
                  required
                />
              </div>

              <div className="straisereqshow-fields">
                <label htmlFor="subject">
                  Subject<span>*</span>:
                </label>
                <input
                  type="text"
                  id="subject"
                  value={subject}
                  style={{ backgroundColor: "#f1f2f6" }}
                  readOnly
                  required
                />
              </div>
              <div className="straisereqshow-fields">
                <label htmlFor="folder">Folder:</label>
                <input
                  type="text"
                  id="folder"
                  value={folderName ? folderName : "Not Found"}
                  style={{ backgroundColor: "#f1f2f6" }}
                  readOnly
                  required
                />
              </div>
              <div className="straisereqshow">
                <label htmlFor="upload-files"> Upload Files:</label>
                <div className="files-wrappper">
                  {files?.length !== 0 ? (
                    files?.map((curr, inx) => {
                      const fileType = curr?.aws_name?.split(".").at(-1);
                      let icon = "";

                      switch (fileType) {
                        case "png":
                        case "jpg":
                        case "jpeg":
                          icon = imageIcon;
                          break;

                        case "pdf":
                          icon = pdfIcon;
                          break;

                        case "docx":
                        case "doc":
                          icon = officeIcon;
                          break;

                        case "xlsx":
                        case "xls":
                        case "csv":
                          icon = excelIcon;
                          break;

                        case "mp4":
                        case "mkv":
                        case "webm":
                          icon = videoFile;
                          break;

                        default:
                          icon = unknownFile;
                      }

                      return (
                        <>
                          <div className="file-upload">
                            <Link
                              className="file-upload-link"
                              to={`/app-file-viewer`}
                              onClick={() => {
                                localStorage.setItem("filePath", curr?.path);
                                localStorage.setItem(
                                  "fileType",
                                  curr?.aws_name?.split(".").at(1)
                                );
                              }}
                            >
                              {icon !== "" ? (
                                <img
                                  src={icon}
                                  alt="File Icon"
                                  className="file-upload-image"
                                />
                              ) : (
                                <HelpCenterIcon />
                              )}
                              {curr?.aws_name
                                ? curr?.aws_name
                                : "unsupported format"}
                            </Link>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <div className="files-upload">
                      <input
                        type="text"
                        id="files"
                        value={"Not Found"}
                        style={{ backgroundColor: "#f1f2f6" }}
                        readOnly
                      />
                    </div>
                  )}
                </div>
              </div>
              {requestType === "internal" && (
                <>
                  <div className="straisereq-fields">
                    <label htmlFor="contact-email">
                      Location<span>*</span>:
                    </label>
                    <input
                      style={{ backgroundColor: "#f1f2f6" }}
                      type="text"
                      value={location}
                      id="contact-email"
                      readOnly
                      required
                    />
                  </div>
                  <div className="stcreate-fields">
                    <label htmlFor="severity">
                      Select Team<span>*</span>:
                    </label>
                    <input
                      style={{ backgroundColor: "#f1f2f6" }}
                      type="text"
                      value={teamData}
                      id="contact-email"
                      readOnly
                      required
                    />
                  </div>
                </>
              )}
              <div className="straisereqshow-fields">
                <label htmlFor="description">
                  Description<span>*</span>:
                </label>
                <textarea
                  id="description"
                  rows="5"
                  value={description}
                  style={{ backgroundColor: "#f1f2f6" }}
                  readOnly
                  required
                ></textarea>
              </div>

              <div
                className="straisereqshow-fields"
                style={{ justifyContent: "center" }}
              >
                <button
                  type="submit"
                  className="straisereqshow-button"
                  onClick={(e) => {
                    e.preventDefault();
                    const promise = new Promise((resolve) => {
                      dispatch(
                        raiseRequestDataAction([
                          completeData?.requestor?.id?.toString(),
                          completeData?.requestor?.username,
                          service_org_id,
                          completeData?.connect_folder_name,
                          completeData?.connect_folder_id,
                          completeData?.connect_folder,
                          completeData,
                        ])
                      );

                      if (completeData?.requestor?.org_id?.toString() === "0") {
                        dispatch(
                          selectedOrganizationSTAction([
                            completeData?.requestor?.id?.toString(),
                            completeData?.requestor?.organization_name,
                          ])
                        );
                      } else {
                        dispatch(
                          selectedOrganizationSTAction([
                            completeData?.requestor?.org_id?.toString(),
                            completeData?.requestor?.organization_name,
                          ])
                        );
                      }
                      dispatch(notConnectedAmniusAction(false));
                      resolve(true);
                    });

                    promise.then((res) => {
                      if (res) {
                        if (requestType === "internal") {
                          navigate("/create-internal-service-ticket");
                        } else navigate("/service-ticket-create");
                      }
                    });
                  }}
                >
                  Create Ticket
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loader}
    </>
  );
};

export default STRaiseRequestFormShow;
