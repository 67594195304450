import "./IFramePopup.css";

import Iframe from "react-iframe";
import React from "react";

const IFramePopup = ({ hide, filePath }) => {
  let url = filePath;

  if (filePath?.includes("youtube.com") && filePath?.includes("watch?v="))
    url = filePath?.replace("watch?v=", "embed/");
  if (filePath?.includes("youtu.be")) {
    const code = filePath?.split("/").pop();
    url = `https://www.youtube.com/embed/${code}`;
  }

  return (
    <div className="i-frame-parent">
      <div className="i-frame-button-container">
        <a href={filePath} target="_blank" rel="noreferrer">
          Open
        </a>
        <button onClick={() => hide(false)}>Close</button>
      </div>
      <div className="i-frame-main">
        <Iframe
          url={url}
          width="100%"
          height="100%"
          id="filePath"
          className=""
          display="block"
          position="relative"
        />
      </div>
    </div>
  );
};

export default IFramePopup;
